import React from 'react';
import Helmet from 'react-helmet';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import Project from '../components/Project';
import Sidebar from '../components/Sidebar';

class IndexRoute extends React.Component {
	render() {
		const items = [];
		const { title, subtitle } = this.props.data.site.siteMetadata;
		const projects = this.props.data.allMarkdownRemark.edges;
		projects.forEach(project => {
			items.push(<Project data={project} key={project.node.frontmatter.link} />);
		});

		return (
			<Layout>
				<div>
					<Helmet>
						<title>{title}</title>
						<meta name="description" content={subtitle} />
					</Helmet>
					<Sidebar {...this.props} />
					<div className="content">
						<div className="content__inner">{items}</div>
					</div>
				</div>
			</Layout>
		);
	}
}

export default IndexRoute;

export const pageQuery = graphql`
	query ProjectsQuery {
		site {
			siteMetadata {
				title
				subtitle
				copyright
				menu {
					label
					path
				}
				author {
					name
					email
					bluesky
					github
				}
			}
		}
		allMarkdownRemark(
			limit: 1000
			filter: { frontmatter: { layout: { eq: "project" }, draft: { ne: true } } }
			sort: { order: DESC, fields: [frontmatter___date] }
		) {
			edges {
				node {
                    html
					fields {
						slug
						categorySlug
					}
					frontmatter {
						title
                        link
                        tags
					}
				}
			}
		}
	}
`;
