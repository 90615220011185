import React from 'react';
import './style.scss';

class Project extends React.Component {
	render() {
		const {
			title,
			link,
			tags,
		} = this.props.data.node.frontmatter;
		const { html } = this.props.data.node;

		const tagsBlock = (
			<div className="project__tags">
				<ul className="project__tags-list">
					{tags &&
						tags.map((tag, i) => (
							<li className="project__tags-list-item" key={tag}>
								{tags[i]}
							</li>
						))}
				</ul>
			</div>
		);

		return (
			<div className="project">
				<h2 className="project__title">
					<a className="project__title-link" href={link} target="_blank" rel="noopener noreferrer">
						{title}
					</a>
				</h2>
				<div
					className="project__body"
					/* eslint-disable-next-line react/no-danger */
					dangerouslySetInnerHTML={{ __html: html }}
				/>
				{tagsBlock}
			</div>
		);
	}
}

export default Project;
